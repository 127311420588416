.iconbullet {
    max-width: 26px;
    width: 26px;
}

.forminputnumber {
    min-width: 2rem;
    width: 4rem !important;
    max-width: 10rem !important;
    color: #ffffff;
}

.forminputmode {
    min-width: 4rem;
    width: 100px !important;
    max-width: 100px !important;
    color: #ffffff;
}

.mbfixtab {
      margin-bottom: 140px;
  }


.fixnavbottom {
    background-color: #23242d;
    border-color: #181924;
}

.divweather {
  min-width: calc(1vw * 10) + "px";
  padding-left: 10px;
  padding-right: 30px;
}

.texttitle {
  font-size: calc(1vw + 1vh + 1vmin)!important;
}

.textdewpoint {
  font-size: calc(0.6vw + 0.6vh + 0.6vmin);
  word-wrap: break-word;
  white-space: nowrap;
}

.textweather {
  /* color: rgb(0, 255, 0); */
  margin: 0;
  font-size: calc(0.55vw + 0.5vh + 0.5vmin);
  word-wrap: break-word;
  white-space: nowrap;
}

.textweathersmall {
  font-size: calc(0.35vw + 0.35vh + 0.3vmin);
}

.div-top-right {
    cursor: pointer;
    display: block;
    float: right;
    z-index: 3;
    position: absolute; /*newly added*/
    right: 5px; /*newly added*/
    top: 5px; /*newly added*/
}

.form-control::-webkit-input-placeholder {
    color: red!important;
}