// Here you can add other styles

a {
    text-decoration: none !important;
}

.textweather p {
    color: red;
    margin: 0;
    font-size: calc(4vw + 4vh + 2vmin);
}

.texttitle {
    font-size: calc(1vw + 1vh + 1vmin) !important;
}

.textfooter {
    font-size: calc(0.6vw + 0.6vh + 0.5vmin) !important;
    word-wrap: break-word;
    white-space: nowrap;
}


.c-dark-theme a.alink {
    
}
