.btn {
    font-family: 'Ubuntu', sans-serif;
    position: relative;
    font-weight: 400;
    font-size: calc(0.55vw + 0.5vh + 0.5vmin);
    word-wrap: break-word;
    white-space: nowrap;
    line-height: 2;
    height: 50px;
    transition: all 200ms linear;
    border-radius: 4px;
    width: 240px;
    letter-spacing: 1px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-self: center;
    border: none;
    cursor: pointer;
    box-shadow: 0 12px 35px 0 rgba(16, 39, 112, .25);
    outline: 0;
    text-transform: capitalize;
}

.statusonventilator {
    animation: rotation 0.7s infinite linear;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.statusoffventilator {
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.statustimeractive {
    animation: rotation 10s infinite linear;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }



  .statusoffmistmaker {
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}


/*.statusonmistmaker {
    filter: linear-gradient(0deg, #083b09, #00ff15);
    -webkit-animation: brightness 3s infinite;
    -moz-animation: brightness 3s infinite;
    animation: brightness 3s infinite;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}*/

.brightness {
    -webkit-animation: brightness 3s infinite;
}

@-webkit-keyframes brightness {
    0%, 100% {
        -webkit-filter: brightness(none);
    }

    50% {
        -webkit-filter: brightness(10);
    }
}

.statusonmistmaker {
    /*filter: breath 3s infinite alternate linear;*/
    /*background: transparent;
    background: linear-gradient(0deg, #083b09, #00ff15);*/
   /* -webkit-filter: gradient 3s infinite alternate linear;*/
/*    background-size: 300% 300%;
    -webkit-animation: breath 3s infinite alternate linear;
    -moz-animation: gradient 3s infinite alternate linear;
    animation: gradient 3s ease alternate infinite;*/
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}


@-webkit-keyframes gradient {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}
@-moz-keyframes gradient {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}
@keyframes gradient {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}

.imgFrame {
    position: relative!important;
    top: 0;
    left: 0;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }

.timerfront {
    position: absolute!important;
    top: 5px;
    left: auto;
    right: auto;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.timerback {
    position: relative !important;
    top: 5px;
    left: auto;
    right: auto;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

  .textactive {
    color: white;
    white-space: nowrap;
    font-size: calc(0.6vw + 0.6vh + 0.5vmin);
    word-wrap: break-word;
    white-space: nowrap;
  }

  .textinactive {
    color: #664444; 
    white-space: nowrap;
    font-size: calc(0.6vw + 0.6vh + 0.5vmin);
    word-wrap: break-word;
    white-space: nowrap;
  }